import { Button } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowResponse, QuestionType, YedQuestion } from '~src/api';
import { surveyFlowRoot } from '~src/constants';
import { useAddAnswer } from '~src/hooks/useAddAnswer';
import { devlogger } from '~src/utils';
import { CardContentStack } from '../Card';
import { ImageUpload } from '../ImageUpload/ImageUpload';
import { FlowItemDescription } from './components/FlowItemDescription';
import { FlowItemImage } from './components/FlowItemImage';
import { FlowItemTitle } from './components/FlowItemTitle';
import { InputErrorMessages } from './YedQuestion/YedTextInput/YedTextInput';

const SurveyImageUpload: React.FC<FlowResponse> = ({ flowItem, id }) => {
  const { t } = useTranslation();
  const { title, description, question, imgUrl, imageUploadProps } =
    flowItem || {};

  const { buttons = [] } = question as YedQuestion;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { addAnswer } = useAddAnswer(surveyFlowRoot);

  const imageUploadRef = useRef<{ sendFiles: () => Promise<any> }>(null);

  // imageUploadProps are mandatory for image-upload flowItem
  if (!imageUploadProps) {
    devlogger('ImageUploadProps are missing in the flowItem:', flowItem);
    return null;
  }
  const { minImages } = imageUploadProps || {};

  async function handleImageUploadAnswer() {
    setIsLoading(true);
    try {
      if (imageUploadRef.current) {
        setErrorMessage(null);
        const uploadImages = await imageUploadRef.current.sendFiles(); // Call sendFiles from child component

        if (uploadImages?.length >= minImages)
          void addAnswer({
            variables: {
              id: id ?? '',
              answer: [
                {
                  name: 'images',
                  type: QuestionType.Text,
                  value: JSON.stringify(uploadImages), // [{id: 1, filename: 'image.jpg' }]
                },
                {
                  name: 'yed-button-option',
                  type: QuestionType.ButtonOptions,
                  value: buttons[0]?.value.toString(), // button value represents the "edgeNumber"
                },
              ],
            },
          });
        else
          setErrorMessage(t('errors.imageUpload.atleastXImage', { minImages }));
      } else setErrorMessage(t('errors.imageUpload.general'));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(t('errors.imageUpload.general'));
      devlogger('Error uploading images:', error);
      return;
    }
  }

  return (
    <CardContentStack marginTop={[2, null, 'unset']}>
      <FlowItemTitle title={title} />
      <FlowItemImage
        imgUrl={imgUrl}
        maxWidth={imgUrl ? [null, null, '200px', '250px'] : '90px'}
      />
      <FlowItemDescription description={description} />
      {/* Image Upload content */}
      <ImageUpload ref={imageUploadRef} {...imageUploadProps} />
      <Button
        onClick={(e) => {
          e.preventDefault();
          void handleImageUploadAnswer();
        }}
        isLoading={isLoading}
      >
        {t('common.continue')}
      </Button>
      {errorMessage && <InputErrorMessages errorMessage={errorMessage} />}
    </CardContentStack>
  );
};

export { SurveyImageUpload };
